<template>
<div class="s-frame">
    <a-layout>
        <a-layout-content>
            <a-row class="s-layout" style="padding-left:0;padding-right:0">
                <a-col :span="24" style="display:flex">
                    <div class="s-sidebar-div">
                        <div class="s-s-font1" style="margin-bottom:61px">상위노출 전략분석</div>
                        <a-input class="s-input1" style="width:239px" v-model="keywordName" @pressEnter="invokeSearch()" placeholder="키워드입력">
                            <div slot="suffix">
                                <img src="@/assets/images/v4-icon/search.png" style="cursor:pointer;" v-on:click="invokeSearch()" />
                            </div>
                        </a-input>
                        <a-select class="s-t-select1" v-model="mallType" :value="ShoppingMall.NAVER_STORE" @change="onChangeMallType">
                            <a-select-option class="s-t-select1-item" :value="ShoppingMall.NAVER_STORE">
                                <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-naver.png?alt=media&token=8f71b90e-e771-46ae-825b-4111706156a7" style="height:20px" /><span style="padding-left:10px">네이버 스마트스토어</span>
                            </a-select-option>
                            <a-select-option :value="ShoppingMall.COUPANG">
                                <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-coupang.png?alt=media&token=8f9fcc4a-911c-4e8a-8b3b-b9c82ae8cd8c" style="height:20px" /><span style="padding-left:10px">쿠팡</span>
                            </a-select-option>
                            <a-select-option :value="ShoppingMall.TMON">
                                <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-tmon.png?alt=media&token=e94b55ad-6ad9-46aa-9ea6-4907667ad368" style="height:20px" /><span style="padding-left:10px">티몬</span>
                            </a-select-option>
                            <a-select-option :value="ShoppingMall.WEMAFE">
                                <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-wemafe.png?alt=media&token=91b750f8-8fd1-4089-b9d9-d0408350495c" style="height:20px" /><span style="padding-left:10px">위메프</span>
                            </a-select-option>
                            <a-select-option :value="ShoppingMall.KAKAO">
                                <img src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fshop-kakao.png?alt=media&token=c1404cd1-bf08-4594-9f4a-e70751c3e31d" style="height:20px" /><span style="padding-left:10px">카카오</span>
                            </a-select-option>
                        </a-select>
                        <a-button class="s-s-btn1" :disabled="!keywordName" v-on:click="invokeSearch()"> 분석하기 </a-button>
                    </div>
                    <div class="s-contents-div" style="overflow-y:scroll">
                        <div style="margin:0 auto;text-algin:center; max-width: 1150px;">
                            <div class="s-s-font3" style="margin-bottom:31px">상위노출 분석</div>
                            <div class="s-s-font2" style="margin-bottom:12px">목표 키워드 : <span>{{skeyword}}</span></div>
                            <div class="" style="margin-bottom:88px">해당 키워드에 대한 상위노출 분석 데이터입니다.</div>
                            <a-row>
                                <a-col :span="24">
                                    <div>
                                        <h1 class="s-f-h1">1페이지 상위노출 분석</h1>
                                    </div>
                                    <div style="display:flex;">
                                        <div class="s-s-font5" style="margin-bottom:10px">1page 전체 제품 1개월 평균 예측치</div>
                                        <span class="s-s-span5">※ 묶음 상품을 제외한 데이터</span>
                                    </div>
                                    <div style="display:flex">
                                        <div style="width:33%">
                                            <div class="s-t-div3" style="margin-right:20px">
                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/count-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            월평균 판매개수
                                                        </div>
                                                        <div class="s-t-value">
                                                            {{(avgPurchase40).toLocaleString()}}<span>개</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width:33%">
                                            <div class="s-t-div3">
                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/count-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            평균가격
                                                        </div>
                                                        <div class="s-t-value">
                                                            {{(avgPrice40).toLocaleString()}}<span>원</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width:33%">
                                            <div class="s-t-div3" style="margin-left:20px">

                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/sales-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            월평균 매출
                                                        </div>
                                                        <div class="s-t-value">
                                                            {{(avgPurchase40*avgPrice40).toLocaleString()}}<span>원</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </a-col>
                            </a-row>
                            <a-row>
                                <div style="display:flex;">
                                    <h5 class="s-f-h5">1page 상위노출 전략</h5>
                                    <a-tooltip>
                                        <template slot="title">
                                            해당 키워드의 상품이 스토어 1페이지에 노출되기 위해 필요한 지표입니다.
                                        </template>
                                        <img style="margin-left:6px;margin-top:27px;" src="@/assets/images/v4-icon/question.svg" />
                                    </a-tooltip>
                                </div>
                                <a-col :span="24">
                                    <div class="new" style="">
                                        <a-col :span="24" class="s-scan-card1">
                                            <a-row style="height:100%">
                                                <a-col :span="24" style="text-align:center" v-if="!avgPurchase40">
                                                    <div class="empty"><img src="@/assets/images/icon/icon-empty.png"><br />
                                                        <span>키워드를 검색해주세요.</span></div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40" style="padding-top: 30px;">
                                                    <div class="fx">
                                                        <span>상위노출 필수항목</span>
                                                    </div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40">
                                                    <div class="f0">
                                                        <span>판매건수</span>
                                                    </div>
                                                    <div class="f1">
                                                        {{parseInt(obj40.purchase).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40">
                                                    <div class="f0">
                                                        <span>텍스트 리뷰수</span>
                                                    </div>
                                                    <div class="f1">
                                                        {{ parseInt(obj40.textreview).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40">
                                                    <div class="f0">
                                                        <span>포토 리뷰수</span>
                                                    </div>
                                                    <div class="f1">
                                                        {{ parseInt(obj40.photoreview).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40">
                                                    <div class="f0">
                                                        <span>찜수</span>
                                                    </div>
                                                    <div class="f1">
                                                        {{ parseInt(obj40.zzim).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase40" style="border-right:unset;">
                                                    <div class="f0">
                                                        <span>Q&A수</span>
                                                    </div>
                                                    <div class="f1">
                                                        {{parseInt(obj40.qa).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </a-col>
                                            </a-row>
                                        </a-col>
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row>
                                <div>
                                    <h5 class="s-f-h5">상위노출 캠페인 요약</h5>
                                </div>
                                <a-col :span="24">
                                    <div style="display:flex">
                                        <div style="width:33%">
                                            <div class="s-t-div4" style="margin-right:20px">
                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/count-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            예상비용(1주)
                                                        </div>
                                                        <div class="s-t-value">
                                                            {{parseInt(payment40).toLocaleString()}}<span>원</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width:33%">
                                            <div class="s-t-div4">
                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/count-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            <span>총 진행 제품수</span>
                                                        </div>
                                                        <div class="s-t-value">
                                                            {{(obj40.purchase).toLocaleString()}}<span>개</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width:33%">
                                            <div class="s-t-div4" style="margin-left:20px">

                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/sales-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            예상기간
                                                        </div>
                                                        <div class="s-t-value" style="font-weight:normal;padding-left:0px;">
                                                            <div v-if="obj40.w5 !=0 && obj40.w4 !=0">
                                                                <span style="font-weight:bold">총 5주</span>
                                                            </div>
                                                            <div v-if="obj40.w5 ==0 && obj40.w4 !=0">
                                                                <span style="font-weight:bold">총 4주</span>
                                                            </div>
                                                            <div v-if="obj40.w1 ==0">
                                                                <span style="font-weight:bold">총 0주</span>
                                                            </div>
                                                            <a-row style="padding-left:0px">
                                                                <a-col :span="12">
                                                                    1주차 - {{obj40.w1}}건
                                                                </a-col>
                                                                <a-col :span="12">
                                                                    2주차 - {{obj40.w2}}건
                                                                </a-col>
                                                                <a-col :span="12">
                                                                    3주차 - {{obj40.w3}}건
                                                                </a-col>
                                                                <a-col :span="12">
                                                                    4주차 - {{obj40.w4}}건
                                                                </a-col>
                                                                <a-col :span="12">
                                                                    5주차 - {{obj40.w5}}건
                                                                </a-col>
                                                            </a-row>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row>
                                <div style="display:flex">
                                    <h5 class="s-f-h5">상위노출 캠페인 ROAS 분석<span>ROAS란? 마케팅비용대비 매출상승률</span></h5>

                                </div>
                                <div style="display:flex">
                                    <div style="width:33%">
                                        <div class="s-t-div3" style="margin-right:20px">
                                            <div class="s-t-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/count-btn.svg">
                                                </div>
                                                <div class="s-t-col1">
                                                    <div>
                                                        1개월 예상매출
                                                    </div>
                                                    <div class="s-t-value">
                                                        {{(avgPurchase40*avgPrice40).toLocaleString()}}<span>원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:33%">
                                        <div class="s-t-div3">
                                            <div class="s-t-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/count-btn.svg">
                                                </div>
                                                <div class="s-t-col1">
                                                    <div>
                                                        예상 마케팅 비용
                                                    </div>
                                                    <div class="s-t-value">
                                                        {{(getTotal40).toLocaleString()}}<span>원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:33%">
                                        <div class="s-t-div3" style="margin-left:20px">

                                            <div class="s-t-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/sales-btn.svg">
                                                </div>
                                                <div class="s-t-col1">
                                                    <div>
                                                        ROAS
                                                    </div>
                                                    <div class="s-t-value" v-if="getROAS40">
                                                        {{getROAS40}}<span>%</span>
                                                    </div>
                                                    <div v-else class="s-t-value">
                                                        0<span>%</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </a-row>
                            <a-row style="margin-top:137px;">
                                <a-col :span="24">
                                    <div>
                                        <h1 class="s-f-h1">1페이지 10위 상위노출 분석</h1>

                                    </div>
                                    <div class="s-s-font5" style="margin-bottom:10px">1page 전체 제품 1개월 평균 예측치</div>
                                    <div style="display:flex">
                                        <div style="width:33%">
                                            <div class="s-t-div3" style="margin-right:20px">
                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/count-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            월평균 판매개수
                                                        </div>
                                                        <div class="s-t-value">
                                                            {{(avgPurchase10).toLocaleString()}}<span>개</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width:33%">
                                            <div class="s-t-div3">
                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/count-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            평균가격
                                                        </div>
                                                        <div class="s-t-value">
                                                            {{(avgPrice10).toLocaleString()}}<span>원</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width:33%">
                                            <div class="s-t-div3" style="margin-left:20px">

                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/sales-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            월평균 매출
                                                        </div>
                                                        <div class="s-t-value">
                                                            {{(avgPurchase10*avgPrice10).toLocaleString()}}<span>원</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </a-col>
                            </a-row>
                            <a-row>
                                <div style="display:flex">
                                    <h5 class="s-f-h5">상위노출 전략</h5>
                                    <a-tooltip>
                                        <template slot="title">
                                            해당 키워드의 상품이 스토어 1페이지 10위권에 노출되기 위해 필요한 지표입니다.
                                        </template>
                                        <img style="margin-left:6px;margin-top:27px;" src="@/assets/images/v4-icon/question.svg" />
                                    </a-tooltip>
                                </div>
                                <a-col :span="24">
                                    <div class="new" style="">
                                        <a-col :span="24" class="s-scan-card1">
                                            <a-row style="height:100%">
                                                <a-col :span="24" style="text-align:center" v-if="!avgPurchase10">
                                                    <div class="empty"><img src="@/assets/images/icon/icon-empty.png"><br />
                                                        <span>키워드를 검색해주세요.</span></div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase10" style="padding-top:30px;">
                                                    <div class="fx">
                                                        <span>상위노출 필수항목</span>
                                                    </div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase10">
                                                    <div class="f0">
                                                        <span>판매건수</span>
                                                    </div>
                                                    <div class="f1">
                                                        {{parseInt(obj10.purchase).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase10">
                                                    <div class="f0">
                                                        <span>텍스트 리뷰수</span>
                                                    </div>
                                                    <div class="f1">
                                                        {{ parseInt(obj10.textreview).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase10">
                                                    <div class="f0">
                                                        <span>포토 리뷰수</span>
                                                    </div>
                                                    <div class="f1">
                                                        {{ parseInt(obj10.photoreview).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase10">
                                                    <div class="f0">
                                                        <span>찜수</span>
                                                    </div>
                                                    <div class="f1">
                                                        {{ parseInt(obj10.zzim).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </a-col>
                                                <a-col :xs="24" :sm="12" :md="8" :lg="8 " :xl="4" class="s-scan-card2" v-if="avgPurchase10" style="border-right:unset;">
                                                    <div class="f0">
                                                        <span>Q&A수</span>
                                                    </div>
                                                    <div class="f1">
                                                        {{parseInt(obj10.qa).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </a-col>

                                            </a-row>
                                        </a-col>
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row>
                                <div>
                                    <h5 class="s-f-h5">상위노출 캠페인 요약</h5>
                                </div>
                                <a-col :span="24">
                                    <div style="display:flex">
                                        <div style="width:33%">
                                            <div class="s-t-div4" style="margin-right:20px">
                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/count-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            예상비용(1주)
                                                        </div>
                                                        <div class="s-t-value">
                                                            {{parseInt(payment10).toLocaleString()}}<span>원</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width:33%">
                                            <div class="s-t-div4">
                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/count-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            <span>총 진행 제품수</span>
                                                        </div>
                                                        <div class="s-t-value">
                                                            {{(obj10.purchase).toLocaleString()}}<span>개</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width:33%">
                                            <div class="s-t-div4" style="margin-left:20px">

                                                <div class="s-t-div3-1">
                                                    <div>
                                                        <img src="@/assets/images/v4-icon/sales-btn.svg">
                                                    </div>
                                                    <div class="s-t-col1">
                                                        <div>
                                                            예상기간
                                                        </div>
                                                        <div class="s-t-value" style="font-weight:normal;padding-left:0px">
                                                            <div v-if="obj10.w5 !=0 && obj10.w4 !=0">
                                                                <span style="font-weight:bold">총 5주</span>
                                                            </div>
                                                            <div v-if="obj10.w5 ==0 && obj10.w4 !=0">
                                                                <span style="font-weight:bold">총 4주</span>
                                                            </div>
                                                            <div v-if="obj10.w1 ==0">
                                                                <span style="font-weight:bold">총 0주</span>
                                                            </div>
                                                            <a-row style="padding-left:0px">
                                                                <a-col :span="12">
                                                                    1주차 - {{obj10.w1}}건
                                                                </a-col>
                                                                <a-col :span="12">
                                                                    2주차 - {{obj10.w2}}건
                                                                </a-col>
                                                                <a-col :span="12">
                                                                    3주차 - {{obj10.w3}}건
                                                                </a-col>
                                                                <a-col :span="12">
                                                                    4주차 - {{obj10.w4}}건
                                                                </a-col>
                                                                <a-col :span="12">
                                                                    5주차 - {{obj10.w5}}건
                                                                </a-col>
                                                            </a-row>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </a-col>
                            </a-row>

                            <a-row>
                                <div style="display:flex">
                                    <h5 class="s-f-h5">상위노출 캠페인 ROAS 분석<span>ROAS란? 마케팅비용대비 매출상승률</span></h5>
                                </div>
                                <div style="display:flex">
                                    <div style="width:33%">
                                        <div class="s-t-div3" style="margin-right:20px">
                                            <div class="s-t-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/count-btn.svg">
                                                </div>
                                                <div class="s-t-col1">
                                                    <div>
                                                        1개월 예상매출
                                                    </div>
                                                    <div class="s-t-value">
                                                        {{(avgPurchase10*avgPrice10).toLocaleString()}}<span>원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:33%">
                                        <div class="s-t-div3">
                                            <div class="s-t-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/count-btn.svg">
                                                </div>
                                                <div class="s-t-col1">
                                                    <div>
                                                        예상 마케팅 비용
                                                    </div>
                                                    <div class="s-t-value">
                                                        {{(getTotal10).toLocaleString()}}<span>원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="width:33%">
                                        <div class="s-t-div3" style="margin-left:20px">
                                            <div class="s-t-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/sales-btn.svg">
                                                </div>
                                                <div class="s-t-col1">
                                                    <div>
                                                        ROAS
                                                    </div>
                                                    <div v-if="getROAS10" class="s-t-value">
                                                        {{getROAS10}}<span>%</span>
                                                    </div>
                                                    <div v-else class="s-t-value">
                                                        0<span>%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <a-button @click="$router.push('/pages/target').catch(() => {})" class="s-btn-apply"> 상위노출 캠페인 신청하기 </a-button>
                            </a-row>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </a-layout-content>
    </a-layout>
    <message :visible="showFlag" contents="<p>회원 전용 서비스입니다.<br/>로그인 후 이용 해 주세요 😘</p>" @closed="onCloseEvent()" @clicked="onClickEvent"></message>
</div>
</template>

<script>
import {
  getCurrentTimeNew,
  getHex,
  replaceAll,
  chkLicense,
  showError,
  showSuccess,
  isMobile,
  dateDiffInDays,
  ShoppingMall
} from "../../components/fnc.js";
import firebase from "firebase";
import Message from "../../components/Message/index";
const random = require("random");
export default {
  components: {
    Message
  },
  data() {
    return {
      rank: 0,
      skeyword: "",
      ShoppingMall: ShoppingMall,
      showFlag: false,
      keywordName: "",
      mallType: ShoppingMall.NAVER_STORE,
      avgPurchase40: 0,
      avgPurchase10: 0,
      avgPrice40: 0,
      avgPrice10: 0,
      payment10: 0,
      payment40: 0,
      obj10: {
        photoreview: 0,
        textreview: 0,
        zzim: 0,
        qa: 0,
        purchase: 0,
        exclude: "",
        w1: 0,
        w2: 0,
        w3: 0,
        w4: 0,
        w5: 0
      },
      obj40: {
        photoreview: 0,
        textreview: 0,
        zzim: 0,
        qa: 0,
        purchase: 0,
        exclude: "",
        w1: 0,
        w2: 0,
        w3: 0,
        w4: 0,
        w5: 0
      },
      loading: false
    };
  },
  computed: {
    getROAS10() {
      return parseInt(
        this.avgPurchase10 * this.avgPrice10 / this.getTotal10 * 100
      );
    },
    getROAS40() {
      return parseInt(
        this.avgPurchase40 * this.avgPrice40 / this.getTotal40 * 100
      );
    },
    getTotal10() {
      return (
        this.obj10.purchase * 5000 +
        this.obj10.textreview * 1000 +
        this.obj10.photoreview * 2000 +
        this.obj10.zzim * 1000 +
        this.obj10.qa * 1000
      );
    },
    getTotal40() {
      return (
        this.obj40.purchase * 5000 +
        this.obj40.textreview * 1000 +
        this.obj40.photoreview * 2000 +
        this.obj40.zzim * 1000 +
        this.obj40.qa * 1000
      );
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      return _mobile;
    },
    getHeight: function() {
      return this.$vssHeight - 450;
    }
  },
  methods: {
    onClickEvent(e) {},
    onCloseEvent() {
      this.showFlag = false;
    },
    onSaveKeyword() {
      this.showFlag = true;
    },
    onChangeMallType() {
      if (this.skeyword) {
        this.$vs.loading();
        this.obj40 = {
          ...this.obj40_source
        };
        this.obj10 = {
          ...this.obj10_source
        };

        if (this.mallType == ShoppingMall.COUPANG) {
          this.avgPurchase10 = parseInt(this.obj10["avgPurchase"] * 0.82);
          this.avgPurchase40 = parseInt(this.obj40["avgPurchase"] * 0.82);
          this.avgPrice10 = parseInt(this.obj10["avgPrice"] * 0.82);
          this.avgPrice40 = parseInt(this.obj40["avgPrice"] * 0.82);
          this.onConvert10(1);
          this.onConvert40(1);
        } else if (this.mallType == ShoppingMall.TMON) {
          this.avgPurchase10 = parseInt(this.obj10["avgPurchase"] * 0.19);
          this.avgPurchase40 = parseInt(this.obj40["avgPurchase"] * 0.19);
          this.avgPrice10 = parseInt(this.obj10["avgPrice"] * 0.19);
          this.avgPrice40 = parseInt(this.obj40["avgPrice"] * 0.19);
          this.onConvert10(0.4);
          this.onConvert40(0.4);
        } else if (this.mallType == ShoppingMall.WEMAFE) {
          this.avgPurchase10 = parseInt(this.obj10["avgPurchase"] * 0.31);
          this.avgPurchase40 = parseInt(this.obj40["avgPurchase"] * 0.31);
          this.avgPrice10 = parseInt(this.obj10["avgPrice"] * 0.31);
          this.avgPrice40 = parseInt(this.obj40["avgPrice"] * 0.31);
          this.onConvert10(0.6);
          this.onConvert40(0.6);
        } else if (this.mallType == ShoppingMall.KAKAO) {
          this.avgPurchase10 = parseInt(this.obj10["avgPurchase"] * 0.5);
          this.avgPurchase40 = parseInt(this.obj40["avgPurchase"] * 0.5);
          this.avgPrice10 = parseInt(this.obj10["avgPrice"] * 0.5);
          this.avgPrice40 = parseInt(this.obj40["avgPrice"] * 0.5);
          this.onConvert10(0.5);
          this.onConvert40(0.5);
        } else {
          this.avgPurchase10 = parseInt(this.obj10["avgPurchase"] * 1);
          this.avgPurchase40 = parseInt(this.obj40["avgPurchase"] * 1);
          this.avgPrice10 = parseInt(this.obj10["avgPrice"] * 1);
          this.avgPrice40 = parseInt(this.obj40["avgPrice"] * 1);
          this.onConvert10(1);
          this.onConvert40(1);
        }
        setTimeout(() => {
          this.$vs.loading.close();
        }, 500);
      }
    },
    onConvert10(ratio) {
      this.obj10["purchase"] = parseInt(this.obj10["purchase"] * ratio);
      this.obj10["textreview"] = parseInt(this.obj10["purchase"] * this.ratio);
      this.obj10["photoreview"] =
        this.obj10["purchase"] - this.obj10["textreview"];
      this.obj10["zzim"] = parseInt(this.obj10["zzim"] * ratio);
      this.obj10["qa"] = parseInt(this.obj10["qa"] * ratio);

      var _w1 = 0;
      var _w2 = 0;
      var _w3 = 0;
      var _w4 = 0;
      var _w5 = 0;

      if (this.obj10["purchase"] >= 461) {
        _w1 = parseInt(this.obj10["purchase"] * 0.07);
        _w2 = parseInt(this.obj10["purchase"] * 0.11);
        _w3 = parseInt(this.obj10["purchase"] * 0.19);
        _w4 = parseInt(this.obj10["purchase"] * 0.26);
        _w5 =
          this.obj10["purchase"] - (_w1 + _w2 + _w3 + _w4) > 0
            ? this.obj10["purchase"] - (_w1 + _w2 + _w3 + _w4)
            : 0;
      } else if (
        this.obj10["purchase"] <= 460 &&
        this.obj10["purchase"] >= 221
      ) {
        _w1 = parseInt(this.obj10["purchase"] * 0.1);
        _w2 = parseInt(this.obj10["purchase"] * 0.18);
        _w3 = parseInt(this.obj10["purchase"] * 0.3);
        _w4 =
          this.obj10["purchase"] - (_w1 + _w2 + _w3) > 0
            ? this.obj10["purchase"] - (_w1 + _w2 + _w3)
            : 0;
      } else if (
        this.obj10["purchase"] <= 220 &&
        this.obj10["purchase"] >= 111
      ) {
        _w1 = parseInt(this.obj10["purchase"] * 0.15);
        _w2 = parseInt(this.obj10["purchase"] * 0.3);
        _w3 =
          this.obj10["purchase"] - (_w1 + _w2) > 0
            ? this.obj10["purchase"] - (_w1 + _w2)
            : 0;
      } else {
        _w1 = parseInt(this.obj10["purchase"] * 0.34);
        _w2 =
          this.obj10["purchase"] - _w1 > 0 ? this.obj10["purchase"] - _w1 : 0;
      }

      this.obj10["w1"] = _w1;
      this.obj10["w2"] = _w2;
      this.obj10["w3"] = _w3;
      this.obj10["w4"] = _w4;
      this.obj10["w5"] = _w5;

      this.payment10 =
        this.obj10["w5"] == 0 ? this.getTotal10 / 4 : this.getTotal10 / 5;
    },
    onConvert40(ratio) {
      this.obj40["purchase"] = parseInt(this.obj40["purchase"] * ratio);
      this.obj40["textreview"] = parseInt(this.obj40["purchase"] * this.ratio);
      this.obj40["photoreview"] =
        this.obj40["purchase"] - this.obj40["textreview"];
      this.obj40["zzim"] = parseInt(this.obj40["zzim"] * ratio);
      this.obj40["qa"] = parseInt(this.obj40["qa"] * ratio);

      var _w1 = 0;
      var _w2 = 0;
      var _w3 = 0;
      var _w4 = 0;
      var _w5 = 0;

      if (this.obj40["purchase"] >= 461) {
        _w1 = parseInt(this.obj40["purchase"] * 0.07);
        _w2 = parseInt(this.obj40["purchase"] * 0.11);
        _w3 = parseInt(this.obj40["purchase"] * 0.19);
        _w4 = parseInt(this.obj40["purchase"] * 0.26);
        _w5 =
          this.obj40["purchase"] - (_w1 + _w2 + _w3 + _w4) > 0
            ? this.obj40["purchase"] - (_w1 + _w2 + _w3 + _w4)
            : 0;
      } else if (
        this.obj40["purchase"] <= 460 &&
        this.obj40["purchase"] >= 221
      ) {
        _w1 = parseInt(this.obj40["purchase"] * 0.1);
        _w2 = parseInt(this.obj40["purchase"] * 0.18);
        _w3 = parseInt(this.obj40["purchase"] * 0.3);
        _w4 =
          this.obj40["purchase"] - (_w1 + _w2 + _w3) > 0
            ? this.obj40["purchase"] - (_w1 + _w2 + _w3)
            : 0;
      } else if (
        this.obj40["purchase"] <= 220 &&
        this.obj40["purchase"] >= 111
      ) {
        _w1 = parseInt(this.obj40["purchase"] * 0.15);
        _w2 = parseInt(this.obj40["purchase"] * 0.3);
        _w3 =
          this.obj40["purchase"] - (_w1 + _w2) > 0
            ? this.obj40["purchase"] - (_w1 + _w2)
            : 0;
      } else {
        _w1 = parseInt(this.obj40["purchase"] * 0.34);
        _w2 =
          this.obj40["purchase"] - _w1 > 0 ? this.obj40["purchase"] - _w1 : 0;
      }

      this.obj40["w1"] = _w1;
      this.obj40["w2"] = _w2;
      this.obj40["w3"] = _w3;
      this.obj40["w4"] = _w4;
      this.obj40["w5"] = _w5;

      this.payment40 =
        this.obj40["w5"] == 0 ? this.getTotal40 / 4 : this.getTotal40 / 5;
    },
    invokeSearch() {
      //DO NOT CHECK LICENSE

      this.skeyword = this.keywordName ? this.keywordName : "";

      if (this.skeyword) {
        this.ratio = random.int(55, 65) / 100;
        this.skeyword = this.keywordName.toUpperCase();
        this.skeyword = replaceAll(this.skeyword, " ", "");
        var dummy = {
          photoreview: 0,
          textreview: 0,
          zzim: 0,
          qa: 0,
          purchase: 0
        };
        this.obj10 = dummy;
        this.obj40 = dummy;

        this.mode = 1;
        this.avgPrice10 = 0;
        this.avgPrice40 = 0;
        this.avgPurchase10 = 0;
        this.avgPurchase40 = 0;
        this.$vs.loading();
        this.onRun();
      }
    },
    onRun() {
      var self = this;
      var __keyword = this.skeyword;
      this.$http
        .get(
          "https://apv14rpqj9.execute-api.ap-northeast-2.amazonaws.com/prod/onGetNaverState?keywordname=" +
            __keyword
        )
        .then(function(r) {
          self.onRun1(r.data.data.msmobile, r.data.data.mspc);
        });
    },
    onRun1(_msmobile, _mspc) {
      var self = this;
      this.storelist = [];
      var __keyword = this.skeyword;
      var _needqty10 = 0;
      var _totalday10 = 0;
      var _needqty40 = 0;
      var _totalday40 = 0;
      var obj10 = {};
      var obj40 = {};

      this.$http
        .post(
          "https://asia-east2-storelink-influencer.cloudfunctions.net/onAnalysisNew4",
          {
            keyword: self.skeyword
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Methods": "POST,HEAD,GET",
              "Access-Control-Allow-Headers":
                "Accept-Language, Content-Type, Accept, Content-Language",
              "Access-Control-Allow-Credentials": true,
              "Access-Control-Max-Age": 86400,
              "Cache-Control": "no-cache"
            }
          }
        )
        .then(function(r) {
          var _s1 = r.data.data.src.indexOf('<script id="__NEXT_DATA__"');
          var _e1 = r.data.data.src.indexOf("script>", _s1);
          var _test = r.data.data.src.substring(_s1, _e1 + 7);

          var _test2 = JSON.parse($($.parseHTML(_test))[0].innerHTML);
          if (_test2.props.pageProps.initialState.mainFilters) {
            if (_test2.props.pageProps.initialState.mainFilters[0]) {
              var _totalproduct =
                _test2.props.pageProps.initialState.mainFilters[0]
                  .filterValues[0].productCount;
              var _totalsearch =
                parseInt(replaceAll(_mspc.toString(), "<", "")) +
                parseInt(replaceAll(_msmobile.toString(), "<", ""));

              var _r_ = _test2.props.pageProps.initialState.products.list;

              var _data_ = [];
              var __tmp__ = [];

              Object.keys(_r_).forEach(function(k) {
                var _v = _r_[k];
                if (_v.item.imageUrl) {
                  __tmp__.push(_v.item);
                }
              });
              __tmp__.forEach(item => {
                if (item.adId) {
                  //skip
                } else {
                  var _relevance_ = item.relevance ? item.relevance : "";
                  var _similarity_ = item.similarity ? item.similarity : "";
                  var _id_ = item.mallProductId
                    ? item.mallProductId
                    : item.mallPid ? item.mallPid : item.id;
                  var _category_ =
                    item.category1Name +
                    " > " +
                    item.category2Name +
                    " > " +
                    item.category3Name;
                  var _title_ = item.productTitle ? item.productTitle : "";
                  var _price_ = item.price ? item.price : 0;
                  var _cumReviewCount_ = item.reviewCountSum
                    ? item.reviewCountSum
                    : 0;
                  var _cumPurchaseCount_ = item.purchaseCnt
                    ? item.purchaseCnt
                    : 0;

                  var _imgUrl_ = item.imageUrl ? item.imageUrl : "";
                  var _hit_ = item.hit ? item.hit : 0;
                  var _isGroup_ = "F";
                  var _is7_ = "F";
                  var _daysSaleScore7_ = item.daysSaleScore7
                    ? item.daysSaleScore7
                    : 0;
                  var _newNormHit_ = item.newNormHit ? item.newNormHit : 0;
                  var _newNormAbuseScore_ = item.newNormAbuseScore
                    ? item.newNormAbuseScore
                    : 0;
                  var _mallId = item.mallName
                    ? item.mallName
                    : item.maker ? item.maker : "X";
                  var _low_ = item.lowMallList ? item.lowMallList : [];
                  if (_low_.length > 0) {
                    _isGroup_ = "T";
                  }
                  if (item.dealName == "럭키투데이") {
                    _is7_ = "7";
                    _mallId = item.productName;
                  } else {
                    _mallId = _mallId;
                  }
                  var _ref_ = item.mallProductUrl ? item.mallProductUrl : "";

                  var _opendate = "";
                  var _gap = 1;

                  if (item.openDate) {
                    _opendate =
                      item.openDate.substr(0, 4) +
                      "/" +
                      item.openDate.substr(4, 2) +
                      "/" +
                      item.openDate.substr(6, 2);

                    _gap = Math.round(
                      Math.abs(dateDiffInDays(new Date(_opendate)) / 30),
                      0
                    );
                    _gap = _gap == 0 ? 1 : _gap;
                    _gap = _gap > 6 ? 6 : _gap;
                  }

                  var _t = {
                    rank: item.rank,
                    relevance: _relevance_,
                    similarity: _similarity_,
                    id: _id_,
                    category: _category_,
                    title: _title_,
                    price: _price_,
                    cumReviewCount: _cumReviewCount_,
                    cumPurchaseCount: _cumPurchaseCount_,
                    imgUrl: _imgUrl_,
                    hit: _hit_,
                    isGroup: _isGroup_,
                    is7: _is7_,
                    daysSaleScore7: _daysSaleScore7_,
                    newNormHit: _newNormHit_,
                    newNormAbuseScore: _newNormAbuseScore_,
                    mallId: _mallId,
                    gap: _gap,
                    opendate: _opendate,
                    ref: _ref_
                  };
                  _data_.push(_t);
                }
              });

              if (!isNaN(_totalproduct)) {
                // console.log(_totalsearch)
                // _totalproduct = Math.round(_totalproduct / 3);
                // console.log(_totalproduct);
                var sref2 = firebase.database().ref("analysis_matrix/");

                sref2.once("value", function(sdata2) {
                  sdata2.forEach(function(sdatas2) {
                    if (
                      sdatas2.val().s_l <= _totalsearch &&
                      sdatas2.val().s_u > _totalsearch &&
                      sdatas2.val().p_l <= _totalproduct &&
                      sdatas2.val().p_u > _totalproduct &&
                      sdatas2.val().type == 10
                    ) {
                      _needqty10 = sdatas2.val().purchase;
                      _totalday10 = sdatas2.val().period;

                      obj10["textreview"] = sdatas2.val().text;
                      obj10["photoreview"] = sdatas2.val().photo;
                      obj10["zzim"] = sdatas2.val().zzim;
                      obj10["qa"] = sdatas2.val().qa;

                      obj10["exclude"] = "F";
                    }

                    if (
                      sdatas2.val().s_l <= _totalsearch &&
                      sdatas2.val().s_u > _totalsearch &&
                      sdatas2.val().p_l <= _totalproduct &&
                      sdatas2.val().p_u > _totalproduct &&
                      sdatas2.val().type == 40
                    ) {
                      _needqty40 = sdatas2.val().purchase;
                      _totalday40 = sdatas2.val().period;

                      obj40["textreview"] = sdatas2.val().text;
                      obj40["photoreview"] = sdatas2.val().photo;
                      obj40["zzim"] = sdatas2.val().zzim;
                      obj40["qa"] = sdatas2.val().qa;

                      obj40["exclude"] = "F";
                    }
                  });

                  var _data = {
                    needqty10: _needqty10,
                    totalday10: _totalday10,
                    needqty40: _needqty40,
                    totalday40: _totalday40,
                    totalproduct: _totalproduct,
                    totalsearch: _totalsearch,
                    mspc: _mspc,
                    msmobile: _msmobile,
                    obj10: obj10,
                    obj40: obj40,
                    page: _data_
                  };

                  self.obj10 = _data.obj10;
                  self.obj40 = _data.obj40;
                  self.obj10["purchase"] = _data.needqty10
                    ? _data.needqty10
                    : 0;
                  self.obj40["purchase"] = _data.needqty40
                    ? _data.needqty40
                    : 0;

                  if (_data.page) {
                    var _totalPurchsae10 = 0;
                    var _totalPrice10 = 0;

                    var _baseTop10Index = 0;
                    var _base1PageIndex = 0;

                    var _totalPurchsae40 = 0;
                    var _totalPrice40 = 0;

                    _data.page.forEach(item => {
                      if (item.imgUrl) {
                        if (item.cumPurchaseCount > 0) {
                          _base1PageIndex++;

                          var _cumPurchaseCount =
                            item.cumPurchaseCount / item.gap;

                          _totalPurchsae40 += _cumPurchaseCount;
                          _totalPrice40 += parseInt(item.price);

                          self.storelist.push({
                            title: item.title,
                            rank: item.rank,
                            purchase: _cumPurchaseCount,
                            isGroup: "F",
                            price: parseInt(item.price),
                            label:
                              item.rank +
                              "순위 제품 1개월 평균 예측치 [" +
                              item.title +
                              "]"
                          });

                          if (item.rank < 11) {
                            _baseTop10Index++;
                            _totalPurchsae10 += _cumPurchaseCount;
                            _totalPrice10 += parseInt(item.price);
                          }
                        } else {
                          self.storelist.push({
                            title: item.title,
                            rank: item.rank,
                            isGroup: item.isGroup,
                            purchase: 0,
                            price: parseInt(item.price),
                            label:
                              item.rank +
                              "순위 제품 1개월 평균 예측치 [" +
                              item.title +
                              "]"
                          });
                        }
                      }
                    });

                    var __dummy__ = self.storelist;

                    self.storelist = __dummy__.sort(function(a, b) {
                      // desc
                      return a.rank < b.rank ? -1 : a.rank > b.rank ? 1 : 0;
                    });
                    if (_totalPrice40 > 0) {
                      self.avgPrice40 = parseInt(
                        _totalPrice40 / _base1PageIndex
                      );
                    }

                    if (_totalPurchsae40 > 0) {
                      self.avgPurchase40 = parseInt(
                        _totalPurchsae40 / _base1PageIndex
                      );

                      if (self.avgPurchase40 > self.obj40["purchase"] * 1.5) {
                        self.obj40["purchase"] = parseInt(
                          self.obj40["purchase"] + self.avgPurchase40 * 0.3
                        );
                        self.obj40["textreview"] = parseInt(
                          self.obj40["textreview"] + self.avgPurchase40 * 0.1
                        );
                        self.obj40["photoreview"] = parseInt(
                          self.obj40["photoreview"] + self.avgPurchase40 * 0.1
                        );
                        self.obj40["zzim"] = parseInt(
                          self.obj40["zzim"] + self.avgPurchase40 * 0.15
                        );
                        self.obj40["qa"] = parseInt(
                          self.obj40["qa"] + self.avgPurchase40 * 0.02
                        );
                      }
                      self.obj40["avgPurchase"] = self.avgPurchase40;
                      self.obj40["avgPrice"] = self.avgPrice40;
                      self.obj40_source = self.obj40;
                    }

                    if (_totalPrice10 > 0) {
                      self.avgPrice10 = parseInt(
                        _totalPrice10 / _baseTop10Index
                      );
                    }

                    if (_totalPurchsae10 > 0) {
                      self.avgPurchase10 = parseInt(
                        _totalPurchsae10 / _baseTop10Index
                      );

                      if (self.avgPurchase10 > self.obj10["purchase"] * 1.5) {
                        self.obj10["purchase"] = parseInt(
                          self.obj10["purchase"] + self.avgPurchase10 * 0.3
                        );
                        self.obj10["textreview"] = parseInt(
                          self.obj10["textreview"] + self.avgPurchase10 * 0.1
                        );
                        self.obj10["photoreview"] = parseInt(
                          self.obj10["photoreview"] + self.avgPurchase10 * 0.1
                        );
                        self.obj10["zzim"] = parseInt(
                          self.obj10["zzim"] + self.avgPurchase10 * 0.15
                        );
                        self.obj10["qa"] = parseInt(
                          self.obj10["qa"] + self.avgPurchase10 * 0.02
                        );
                      }

                      self.obj10["avgPurchase"] = self.avgPurchase10;
                      self.obj10["avgPrice"] = self.avgPrice10;
                      self.obj10_source = self.obj10;
                    }
                    self.onChangeMallType();
                    self.$vs.loading.close();
                  } else {
                    self.$vs.loading.close();
                  }
                });
              } else {
                self.$vs.loading.close();
              }
            } else {
              self.$vs.loading.close();
              showSuccess({
                notify: self.$vs.notify,
                msg: "지원되지 않는 검색어 입니다."
              });
            }
          } else {
            self.$vs.loading.close();
            showSuccess({
              notify: self.$vs.notify,
              msg: "지원되지 않는 검색어 입니다."
            });
          }
        });
    }
  },
  mounted() {
    // mobile 여부에 따라
    if (this._mobile) {
      this.$router.push("/pages/notsupport").catch(() => {});
    } else {
      if (this.pagemode == this.SCAN_PRINT_MODE) {
        this.keywordName = this.$route.query.keyword;
        this.invokeSearch();
      }
    }
  }
};
</script>

<style>
.s-t-select1 .ant-select-selection--single {
  width: 240px;
  height: 60px;
  padding-top: 15px;
  margin-top: 20px;
}

.s-t-select1 span,
.s-t-select1 .ant-select-dropdown-menu-item span {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding-left: 10px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #828282;
}

/* 분석하기 버튼 */
.s-s-btn1 {
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 91px;
  height: 40px;
  float: right;

  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #fff;
  background: #000000;
  border-radius: 5px;
}

/* 가장 상단 타이틀 */
.s-s-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.03em;

  color: #000000;
}

/* 큰 타이틀 */
.s-f-h1 {
  font-family: "Muli";
  font-style: normal;
  font-weight: 300;
  font-size: 30px;

  margin-bottom: 38px;
  letter-spacing: -0.03em;
  border-bottom: 7px solid #f2da00;
  line-height: 20px;
  width: fit-content;
}

/* 작은 타이틀 */
.s-f-h5 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  margin-top: 35px;
  letter-spacing: -0.03em;
}

.s-f-h5 > span {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 180%;
  /* identical to box height, or 22px */

  letter-spacing: -0.03em;
  padding-left: 6px;

  color: #828282;
}

/* 상위노출 캠페인 신청하기 버튼 */
.s-btn-apply {
  margin-top: 93px;
  width: 292px;
  height: 52px;

  background: #0264fb;
  color: #fff;
  float: right;
}

.s-s-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.03em;

  color: #000000;
}

.s-s-font2 > span {
  font-weight: bold;
}

.s-s-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.03em;

  color: #000000;
}

.s-s-font5 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #4f4f4f;
}

.s-s-span5 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.03em;
  margin-left: auto;
  color: #828282;
}

/* 상위노출 전략 div */
.s-scan-card1 {
  border-top: 2px solid #000;
  border-bottom: 1px solid #000;
  text-align: center;
}

.s-scan-card2 {
  border-right: 1px solid #e0e0e0;
}

.s-scan-card2 div {
  margin-top: 20px;
  padding-bottom: 20px;
}

.f0 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.03em;
  border-bottom: 1px solid #e0e0e0;
}

.fx {
  margin-top: 50px;
  height: 72px;
}

/* 상위노출 전략 empty */
.empty {
  color: #ccc;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* 예측치 div */
.s-t-div3 {
  height: 98px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
}

.s-t-div4 {
  height: 160px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
}

.s-t-div4 > div.c25,
.s-t-div3 > div.c25 {
  width: 25%;
}

.s-t-col1 div {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: -0.03em;
  padding-left: 10px;
  color: #000000;
}

.s-t-col1 div.s-t-value {
  font-weight: bold;
}

.s-s-col2 {
  text-align: left;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.03em;

  color: #4f4f4f;
  padding-left: 10px;
  padding-top: 10px;
}

/* 예측치 내부 div */
.s-t-div3-1 {
  padding: 30px;
  display: flex;
}
</style>
